//@author: travis
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25CopyObjectComponent } from "./s25.copy.object.component";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25ContactInfoModule } from "../s25-contact-info/s25.contact.module";

@NgModule({
    declarations: [S25CopyObjectComponent],
    imports: [
        CommonModule,
        FormsModule,
        S25EditableModule,
        S25ItemModule,
        S25LoadingInlineModule,
        S25ContactInfoModule,
    ],
    providers: [S25CopyObjectComponent],
    exports: [S25CopyObjectComponent],
})
export class S25MoreActionsModule {
    constructor() {}
}

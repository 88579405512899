//@author: devin

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25MultiselectComponent } from "./s25.multiselect.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25InfiniteScrollModule } from "../s25-infinite-scroll/s25.infinite.scroll.module";
import { S25MultiselectResultsComponent } from "./s25.multiselect.results.component";
import { S25MultiselectItemComponent } from "./s25.multiselect.item.component";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25MultiselectSearchCriteriaComponent } from "./multiselects/s25.multiselect.search.criteria.component";
import { S25MultiselectPopupComponent } from "./s25.multiselect.popup.component";
import { PopoverModule } from "../s25-popover/popover.module";

@NgModule({
    declarations: [
        S25MultiselectComponent,
        S25MultiselectResultsComponent,
        S25MultiselectItemComponent,
        S25MultiselectSearchCriteriaComponent,
        S25MultiselectPopupComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        S25LoadingInlineModule,
        S25DropdownPaginatedModule,
        S25InfiniteScrollModule,
        S25EditableModule,
        PopoverModule,
    ],
    providers: [
        S25MultiselectComponent,
        S25MultiselectResultsComponent,
        S25MultiselectItemComponent,
        S25MultiselectSearchCriteriaComponent,
        S25MultiselectPopupComponent,
    ],
    exports: [
        S25MultiselectSearchCriteriaComponent,
        S25MultiselectComponent,
        S25MultiselectPopupComponent,
        S25MultiselectResultsComponent,
    ],
})
export class S25MultiselectModule {}

import { CopyPropertiesI } from "../../services/copy.object.service";

export class CopyObjectUtil {
    /*
converts Security properties options to format consumable by micro POST payload
 */
    public static convertSecurityOptions(sourceItemId: number, copyProps: CopyPropertiesI) {
        return {
            olsSource: copyProps.ols.selected ? sourceItemId : undefined,
            apSource: copyProps.ap?.selected ? sourceItemId : undefined,
            copyDates: copyProps?.apDates?.selected ? 1 : 0,
            copyDow: copyProps?.apDOW?.selected ? 1 : 0,
            copyBuffers: copyProps?.apWindows?.selected ? 1 : 0,
        };
    }
}

//@author: devin

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25MultiQueryComponent } from "./s25.multi.query.component";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25SimpleCollapseModule } from "../s25-simple-collapse/s25.simple.collapse.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";

@NgModule({
    declarations: [S25MultiQueryComponent],
    imports: [CommonModule, FormsModule, S25DropdownPaginatedModule, S25SimpleCollapseModule, S25CheckboxModule],
    providers: [S25MultiQueryComponent],
    exports: [S25MultiQueryComponent],
})
export class S25MultiQueryModule {}

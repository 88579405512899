//@author: devin

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from "@angular/core";
import { S25ItemI } from "../../pojo/S25ItemI";
import { MultiselectModelI } from "./s25.multiselect.component";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { jSith } from "../../util/jquery-replacement";
import { Api } from "../../api/api";

export class MultiselectItemApi extends Api {
    static refresh(relativeElem: HTMLElement) {
        return MultiselectItemApi.callApiFn(relativeElem, "s25-ng-multiselect-item", "refresh");
    }
}

@TypeManagerDecorator("s25-ng-multiselect-item")
@Component({
    selector: "s25-ng-multiselect-item",
    template: `
        @if (type === "none") {
            <div class="s25-multiselect-item-none">
                <div class="s25-multiselect-item-col s25-multiselect-item-mask"><i>&#160;</i></div>
                <div class="s25-multiselect-item-col s25-multiselect-item-text">None</div>
            </div>
        }

        @if (type !== "none") {
            <div
                [ngClass]="this.item.isPermanent ? 's25-multiselect-item no-pointer' : 's25-multiselect-item'"
                id="{{ id }}"
                (click)="onClick($event)"
                (keyup.enter)="onClick($event)"
                (keyup.space)="onClick($event)"
            >
                <div class="tb-body">
                    @if (type === "check") {
                        <div class="tb-col s25-checkmark-col">
                            <input
                                type="checkbox"
                                [disabled]="item.isPermanent"
                                [(ngModel)]="item.checked"
                                [attr.aria-label]="'check'"
                                class="c-label--input"
                                name="multiselect-check"
                            />
                            <label class="c-label--checkbox c-margin-bottom--none" for="multiselect-check"></label>
                        </div>
                    }
                    @if (type === "radio") {
                        <div class="tb-col s25-checkmark-col">
                            <input
                                type="radio"
                                [disabled]="item.isPermanent"
                                [checked]="item.checked"
                                [attr.aria-label]="'radio'"
                            />
                        </div>
                    }
                    @if (type === "cross" && !this.item.isPermanent) {
                        <div class="cross">
                            <svg class="c-svgIcon c-margin-right--quarter" role="img">
                                <title>Remove {{ item.itemName }}</title>
                                <use
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                ></use>
                            </svg>
                        </div>
                    }
                    <div class="s25-multiselect-item-text-col {{ type === 'cross' ? 'ngInlineBlock' : 'tb-col' }}">
                        <span>{{ item.itemName }}</span>
                        @if (item.isInactive === 1) {
                            <span class="not_active"> [Not Active] </span>
                        }
                        @if (item.itemDesc && type !== "cross") {
                            <span class="not_active">{{ item.itemDesc }}</span>
                        }
                        @if (showInactive) {
                            <span class="not_active">Inactive</span>
                        }
                    </div>
                    @if (hasQuantity && type !== "cross") {
                        <div>
                            <div class="tb-body">
                                <s25-ng-editable-number
                                    class="picker-col tb-col"
                                    type="integer"
                                    [onUpdate]="onQuantityUpdate"
                                    [readOnly]="item.quantityMax <= 1"
                                    [min]="1"
                                    [max]="item.quantityMax"
                                    [(val)]="item.quantity"
                                    (click)="stopClick($event)"
                                >
                                </s25-ng-editable-number>
                                @if (item.quantityMax) {
                                    <div class="total-col tb-col">
                                        /<span class="total-quantity">{{ item.quantityMax }}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
    `,
    styles: `
        .s25-multiselect-item {
            padding: 0;
        }

        .s25-multiselect-item .tb-body {
            display: flex;
            gap: 0.5em;
            padding: 0.25em 0.5em 0.25em 0.25em;
        }

        .s25-multiselect-item .cross {
            margin: auto;
        }

        .s25-multiselect-item .cross svg {
            margin: 0 !important;
        }

        .s25-multiselect-item .s25-multiselect-item-text-col {
            display: flex;
            flex-wrap: wrap;
            column-gap: 0.5em;
        }

        ::ng-deep .nm-party--on s25-ng-multiselect-item .s25-multiselect-item svg {
            color: rgba(255, 255, 255, 0.5) !important;
        }

        ::ng-deep .nm-party--on s25-ng-multiselect-item .s25-multiselect-item:hover svg {
            color: #e8e9e8 !important;
        }

        ::ng-deep .nm-party--on s25-ng-multiselect-item .s25-multiselect-item-text-col {
            color: #fff;
        }

        ::ng-deep .nm-party--on s25-ng-multiselect-item .s25-multiselect-item-text-col .not_active {
            color: #c7c7c7;
        }

        .no-pointer {
            pointer-events: none !important;
        }
    `,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25MultiselectItemComponent implements OnInit {
    @Input() type: "check" | "radio" | "cross" | "none";
    @Input() showInactive: boolean = false;
    @Input() autoFocus: boolean = false;
    @Input() item: S25ItemI;
    @Input() modelBean: MultiselectModelI;
    @Input() action: ($event: any) => any;

    static QuantityTypes: number[] = [607];

    hasQuantity: boolean = false;

    static count: number = 0;
    id: string;

    destroyed = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.id = "selectItem-" + S25MultiselectItemComponent.count++;
    }

    onQuantityUpdate = () => {
        if (!this.item.checked) {
            this.onClick();
        }
    };

    refresh = () => {
        try {
            this.cd.detectChanges();
        } catch (error: any) {}
    };

    onClick = ($event?: any) => {
        //if permanent, then its initial checked value cannot change!
        if (!this.item.isPermanent) {
            if (this.type === "radio") {
                this.modelBean.selectNone(); //select none first
                this.modelBean.addSelectedItem(this.item); //then add new single selected item
            } else {
                //if cross has no checked status, set it to true so that toggle below removes it (bc toggle inverts checked status)
                if (this.type === "cross" && S25Util.isUndefined(this.item.checked)) {
                    this.item.checked = true;
                }
                this.toggle();
            }
            this.modelBean.onChange?.();
        }

        //any outside action()
        this.action && this.action($event);

        if (!this.destroyed) {
            this.cd.detectChanges();
        }
    };

    stopClick = ($event?: any) => {
        $event.stopPropagation();
    };

    toggle = () => {
        this.item.checked = !this.item.checked;
        if (this.item.checked) {
            this.modelBean.addSelectedItem(this.item);
        } else {
            this.modelBean.removeSelectedItem(this.item);
        }
        if (!this.destroyed) {
            this.cd.detectChanges();
        }
    };

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.hasQuantity =
            (this.item && this.item.hasQuantity) ||
            (this.modelBean && this.modelBean.hasQuantity) ||
            (this.item && S25MultiselectItemComponent.QuantityTypes.indexOf(this.item.itemTypeId) > -1);
        if (this.autoFocus) {
            let elem = jSith.focusable(this.elementRef.nativeElement, 0, true, true);
            elem && elem.focus();
        }
    }

    ngOnDestroy() {
        this.destroyed = true;
    }
}

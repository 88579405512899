import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { CopyObjectService, objPropsI } from "../../services/copy.object.service";
import { StateService } from "../../services/state.service";
import { S25Const } from "../../util/s25-const";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25ContactInfoComponent } from "../s25-contact-info/s25.contact.info.component";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { AssignPolicyService } from "../../services/assign.policy.service";
import { Item } from "../../pojo/Item";
import { jSith } from "../../util/jquery-replacement";
import { OlsService } from "../../services/ols.service";
import { Bind } from "../../decorators/bind.decorator";

@TypeManagerDecorator("s25-ng-copy-object")
@Component({
    selector: "s25-ng-copy-object",
    template: ` <div class="copy-object">
        @if (this.init) {
            <div>
                <table class="{{ this.itemTypeId === 3 ? 'copy-object--contacts' : '' }}">
                    <td>
                        @if (this.hasName) {
                            <tr>
                                <label for="newName">
                                    <span name="newName" class="ngBold ngBlock c-margin-bottom--quarter"
                                        >{{ this.modelBean.itemTypeName }} Name
                                        <s25-ng-editable-text
                                            id="newName"
                                            [max]="40"
                                            [alwaysEditing]="true"
                                            [(val)]="this.newItem.itemName"
                                            (disablingError)="disableButtons($event)"
                                        ></s25-ng-editable-text>
                                    </span>
                                </label>
                            </tr>
                            <tr>
                                @if (this.hasFormalName) {
                                    <label for="formalName">
                                        <span class="ngBold ngBlock c-margin-bottom--quarter"
                                            >Formal Name
                                            <s25-ng-editable-text
                                                id="formalName"
                                                [max]="80"
                                                [alwaysEditing]="true"
                                                [(val)]="this.newItem.itemFormal"
                                                (disablingError)="disableButtons($event)"
                                                [valOnInit]="true"
                                            ></s25-ng-editable-text>
                                        </span>
                                    </label>
                                }
                            </tr>
                        }
                        @if (this.itemTypeId === 3) {
                            <s25-ng-contact-info
                                [(contact)]="this.newItem"
                                [isNew]="false"
                                [fields]="{ hasEmailOptions: false, showEmail: true, showAddress: false }"
                                [data]="{ copyContact: true }"
                                [onSave]="onSave"
                            ></s25-ng-contact-info>
                        }
                        <!--<div *ngFor="let field of this.fields | keyvalue">
                  <label for="{{field.key}}">
                    <span class= 'ngBold ngBlock c-margin-bottom--quarter'>{{field.value.itemName}}
                      <input type="checkbox" id="{{field.key}}" [(ngModel)]="field.value.selected" (change)="onCheck($event)">
                    </span>
                  </label>
                </div>
                --></td>
                    @if (this.itemTypeId != 3) {
                        <td class="object-copy--security">
                            <p class="ngBold">Copy Security</p>
                            @for (field of security | keyvalue; track field) {
                                <div class="c-margin-bottom--half">
                                    <label for="{{ field.key }}">
                                        <span class="ngBlock">
                                            <input
                                                type="checkbox"
                                                id="{{ field.key }}"
                                                [(ngModel)]="field.value.selected"
                                                (change)="onCheck(field.key, $event)"
                                                [disabled]="!field.value.hasPerms"
                                            />
                                            {{ field.value.itemName }}
                                        </span>
                                    </label>
                                    @if (!field.value.selected) {
                                        <p class="ngFinePrint">
                                            {{ field.value.msg }}
                                        </p>
                                    }
                                    @if (field.key === "ap") {
                                        <div class="c-margin-left--double">
                                            @for (det of apDetails | keyvalue; track det) {
                                                <div class="c-margin-bottom--none">
                                                    <label for="{{ det.key }}">
                                                        <span class="ngBlock">
                                                            <input
                                                                type="checkbox"
                                                                id="{{ det.key }}"
                                                                [(ngModel)]="det.value.selected"
                                                                (change)="onCheck(det.key, $event)"
                                                                [disabled]="!field.value.selected"
                                                            />
                                                            {{ det.value.itemName }}
                                                        </span>
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </td>
                    }
                </table>
                <s25-loading-inline model="{}"></s25-loading-inline>
                @if (lowDefaultRights) {
                    <p class="c-warning c-margin-right--half">
                        Your default object level security for {{ modelBean.itemTypeName | lowercase }}s is "No Access"
                        so you will not be able to see the new {{ modelBean.itemTypeName | lowercase }} unless you copy
                        OLS.
                    </p>
                }
                <div class="copy-object--buttons aw-button-group">
                    @if (copyProblems) {
                        <div class="c-warning">
                            There may have been a problem copying assignment policy due to legacy permissions. Please
                            review security for the new object or try copying security again
                            <button
                                class="aw-button aw-button--primary c-margin-right--quarter"
                                [disabled]="disablingError"
                                (click)="copyAp()"
                            >
                                Try Again
                            </button>
                        </div>
                    }
                    @if (!copyProblems) {
                        <button
                            class="aw-button aw-button--primary c-margin-right--double"
                            [disabled]="disablingError || lowDefaultRights"
                            (click)="
                                itemTypeId === 3
                                    ? contactChild.findDuplicateContacts('edit', objectProps)
                                    : onSave('edit')
                            "
                        >
                            Copy and Edit
                        </button>
                        <button
                            class="aw-button aw-button--primary"
                            [disabled]="disablingError"
                            (click)="
                                itemTypeId === 3
                                    ? contactChild.findDuplicateContacts('create', objectProps)
                                    : onSave('create')
                            "
                        >
                            Copy and Create Another
                        </button>
                    }
                </div>
            </div>
        }
    </div>`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25CopyObjectComponent implements OnInit {
    @Input() modelBean?: any;
    @Input() itemTypeId: number;
    @Input() itemId: number;

    @ViewChild(S25ContactInfoComponent) contactChild: S25ContactInfoComponent;

    newItem: any = {};
    security: Record<string, objPropsI>; //CopyPropertiesI;
    apDetails: Record<string, objPropsI>;
    init = false;
    hasName = true;
    hasFormalName = true;
    disablingError: boolean; // used in conjunction with disablingError output from s25.editable.abstract.ts for editable text above
    objectProps: {
        itemTypeId: Item.Id;
        itemId: number;
        newItem: any;
        security: Record<string, objPropsI>;
    };
    copyProblems: boolean = false;
    copyDifferences: any;
    lowDefaultRights = false;
    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    async ngOnInit(): Promise<void> {
        S25LoadingApi.init(this.elementRef.nativeElement);

        S25Util.extend(this.modelBean, {
            itemTypeId: this.itemTypeId,
            itemTypeName: S25Const.itemId2Display[this.itemTypeId].singular,
        });

        this.hasName = [2, 4, 6].indexOf(this.itemTypeId) > -1;
        this.hasFormalName = [2, 4].indexOf(this.itemTypeId) > -1;

        //Gets the details for fields that will be presented to user in the copy form. -(Mostly name/formal name)
        CopyObjectService.getOriginal(this.itemTypeId, this.itemId).then((resp: any) => {
            this.getFields().then(() => {
                this.newItem = resp;
                this.objectProps = {
                    itemTypeId: this.itemTypeId,
                    itemId: this.itemId,
                    newItem: this.newItem,
                    security: this.security,
                };

                this.init = true;
                this.cd.detectChanges();
                S25LoadingApi.destroy(this.elementRef.nativeElement);
            });
        });
    }
    reset() {
        this.cd.detectChanges();
    }
    disableButtons(data: boolean) {
        data ? (this.disablingError = true) : (this.disablingError = false);
        this.cd.detectChanges();
    }
    async getFields() {
        const fls = await CopyObjectService.copyRights(this.itemTypeId);
        this.security = {
            ols: {
                itemName: "Object Level Security",
                selected: fls.ols,
                hasPerms: fls.ols,
                msg: "The default object level security will be used on the new " + this.modelBean.itemTypeName,
            },
            notify: {
                itemName: "Notification Policy",
                selected: fls.notify,
                hasPerms: fls.notify,
            },
        };

        if ([4, 6].indexOf(this.itemTypeId) > -1) {
            this.security.ap = {
                itemName: "Assignment Policy",
                selected: fls.ap,
                hasPerms: fls.ap,
                msg: "The default assignment policy will be used on the new " + this.modelBean.itemTypeName,
            };

            this.apDetails = {
                apWindows: {
                    itemName: "Window Exceptions",
                    selected: fls.ap,
                    hasPerms: fls.ap,
                    // msg: "The default assignment policy will be used on the new " + this.modelBean.itemTypeName,
                },
                apDates: {
                    itemName: "Day Of Week Exceptions",
                    selected: fls.ap,
                    hasPerms: fls.ap,
                    // msg: "The default assignment policy will be used on the new " + this.modelBean.itemTypeName,
                },
                apDOW: {
                    itemName: "Date Exceptions",
                    selected: fls.ap,
                    hasPerms: fls.ap,
                    // msg: "The default assignment policy will be used on the new " + this.modelBean.itemTypeName,
                },
            };
        }
    }

    onCheck(key: string, event: Event) {
        if (key === "ap" && !(event.target as HTMLElement).checked) {
            jSith.forEach(this.apDetails, (key, val) => {
                val.selected = false;
            });
        } else if (key === "ols" && !(event.target as HTMLElement).checked) {
            CopyObjectService.hasOlsOverride(this.itemTypeId).then((override) => {
                if (!override) {
                    OlsService.getOls([-1], this.itemTypeId, "Edit").then((ols) => {
                        if (ols.length && "N" === ols[0].access_level) {
                            this.lowDefaultRights = true;
                            this.cd.detectChanges();
                        }
                    });
                }
            });
        } else {
            this.lowDefaultRights = false;
        }
        this.cd.detectChanges();
    }

    copyAp() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        this.copyProblems = false;

        this.cd.detectChanges();
        return AssignPolicyService.copy(
            this.objectProps.itemTypeId,
            this.objectProps.itemId,
            this.objectProps.newItem,
            false,
        ).then(
            (resp) => {
                if (resp.isEqual === false) {
                    this.copyProblems = true;
                    this.copyDifferences = resp.differences;
                }
                S25LoadingApi.destroy(this.elementRef.nativeElement);
                this.cd.detectChanges();
            },
            (err) => {
                console.error(err);
                S25LoadingApi.destroy(this.elementRef.nativeElement);
                this.cd.detectChanges();
            },
        );
    }

    @Bind
    onSave(action: string, props?: any) {
        S25LoadingApi.init(this.elementRef.nativeElement);
        this.disableButtons(true);
        props ??= this.objectProps;
        let serviceProps = Object.assign({}, props.security, this.apDetails);
        this.cd.detectChanges();

        if (this.validate()) {
            CopyObjectService.copyObject(props.itemTypeId, props.itemId, props.newItem, serviceProps).then(
                (resp) => {
                    props.newItem.itemId = resp.itemId;
                    props.newItem.itemTypeId = props.itemTypeId;

                    if (resp.security?.isEqual === false) {
                        this.copyProblems = true;
                        this.copyDifferences = resp.security.differences;
                    }
                    this.cd.detectChanges();
                    S25LoadingApi.destroy(this.elementRef.nativeElement);

                    if (action === "create") {
                        StateService.putRecentlyViewedItemFetchName(props.itemTypeId, resp.itemId);
                        this.disableButtons(false);
                    } else if (action === "edit") {
                        StateService.gotoItem(props.newItem, false);
                    }
                },
                (err: any) => {
                    S25Util.showError(err, "Copy Failed");
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                    this.disableButtons(false);
                    this.cd.detectChanges();
                },
            );
        }
    }

    validate() {
        if (this.itemTypeId === 3) {
            return this.contactChild.validate();
        } else if (this.newItem && !this.newItem.itemName) {
            alert("Please enter an unused name");
            return false;
        } else {
            return true;
        }
    }
}

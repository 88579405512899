//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { SearchService } from "../../services/search/search.service";
import { jSith } from "../../util/jquery-replacement";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-multi-query")
@Component({
    selector: "s25-multi-query",
    template: ` @if (this.init && queries.length > 0) {
        <div>
            @if (!this.dropdown && !this.simpleCollapse) {
                <div>
                    <div class="multiQueryListWrapper content-wrapper">
                        @for (s of queries; track s) {
                            <div class="multiQueryList">
                                <s25-ng-checkbox
                                    class="c-margin-left--quarter"
                                    [(modelValue)]="s.chosen"
                                    (modelValueChange)="updateItem(s)"
                                    >{{ s.itemName }}</s25-ng-checkbox
                                >
                            </div>
                        }
                    </div>
                    @if (!noUpdate) {
                        <button class="aw-button aw-button--primary" (click)="this.update()">Update</button>
                    }
                </div>
            }
            @if (!this.dropdown && this.simpleCollapse) {
                <div>
                    <div class="c-objectDetails c-objectDetails--borderedSection">
                        <div class="c-sectionHead">
                            <p>Select Searches</p>
                        </div>
                        <s25-simple-collapse [defaultCollapsed]="true">
                            <div class="c-evddWrapper">
                                <div class="multiQueryListWrapper">
                                    @for (s of queries; track s) {
                                        <div class="multiQueryList">
                                            <s25-ng-checkbox
                                                [(modelValue)]="s.chosen"
                                                (modelValueChange)="updateItem(s)"
                                                >{{ s.itemName }}</s25-ng-checkbox
                                            >
                                        </div>
                                    }
                                </div>
                                @if (!noUpdate) {
                                    <button
                                        class="aw-button aw-button--primary c-margin-bottom--half"
                                        (click)="this.update()"
                                    >
                                        Update
                                    </button>
                                }
                            </div>
                        </s25-simple-collapse>
                    </div>
                </div>
            }
            @if (this.dropdown) {
                <div>
                    <s25-dropdown-paginated
                        [choice]="choice"
                        [model]="this.dropdownModel"
                        [onSelect]="this.updateItem"
                        [(chosen)]="this.chosenDropdown"
                    ></s25-dropdown-paginated>
                    <ng-template #choice let-item="item">
                        <div>
                            <span [innerHTML]="item.itemName"></span>
                            @if (item.itemDesc) {
                                <span>&nbsp;({{ item.itemDesc }})</span>
                            }
                        </div>
                    </ng-template>
                </div>
            }
        </div>
    }`,
    styles: `
        .c-sectionHead {
            padding: 7px 10px !important;
            min-width: 11em;
            text-align: left;
        }

        s25-simple-collapse .c-evddWrapper {
            position: absolute;
            z-index: 700;
            background-color: white;
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.15);
            padding: 0;
            text-align: center;
        }

        s25-simple-collapse .multiQueryListWrapper {
            max-height: 50vh;
            overflow: auto;
            padding: 0.5em 0 0 0.5em;
            text-align: left;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }

        s25-simple-collapse button {
            margin: 0.5em;
        }

        s25-simple-collapse .multiQueryList label {
            width: 100%;
            cursor: pointer;
        }

        .c-objectDetails--borderedSection {
            margin-bottom: 0;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25MultiQueryComponent implements OnInit {
    @Input() itemTypeId: number;
    @Input() selectedQueries: any[];
    @Input() whiteList: any[];
    @Input() noUpdate: boolean;
    @Input() onAction: (queryArray: any) => any = undefined;
    @Input() onItemAction: (query: any, chosenQueries: any) => any = undefined;
    @Input() dropdown: boolean = false;
    @Input() simpleCollapse: boolean;
    @Output() onChange = new EventEmitter<any[]>();
    @Output() onItemChange = new EventEmitter<{ item: any; selected: any[] }>();

    init: boolean = false;
    queries: any[] = [];
    chosenDropdown: any;

    dropdownModel: any = {
        itemNameProp: "itemName",
        placeholder: "Select a search",
        emptyText: "No searches Available",
        searchEnabled: true,
    };

    getChosenQueries = () => {
        if (this.dropdown) {
            jSith.forEachObj(this.queries, (query) => {
                query.chosen =
                    query && this.chosenDropdown && parseInt(query.itemId) === parseInt(this.chosenDropdown.itemId);
            });
        }
        return this.queries.filter((s: any) => s.chosen);
    };

    update = () => {
        this.cd.detectChanges();
        const chosenQueries = this.getChosenQueries();
        this.onAction?.(chosenQueries);
        this.onChange.emit(chosenQueries);
    };

    updateItem = (s: any) => {
        this.cd.detectChanges();
        if (this.dropdown) {
            s = this.chosenDropdown;
        }
        const chosenQueries = this.getChosenQueries();
        this.onItemAction?.(s, chosenQueries);
        this.onItemChange.emit({ item: s, selected: chosenQueries });
    };

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        let searchDefer = jSith.defer();

        if (this.whiteList && this.whiteList.length) {
            this.queries = [].concat(this.whiteList);
            searchDefer.resolve();
        } else {
            SearchService.getStrictSearches(this.itemTypeId).then((queries) => {
                this.queries = S25Util.array.uniqueByProp([].concat((queries && queries.opt) || []), "itemId");
                searchDefer.resolve();
            });
        }

        searchDefer.promise.then(() => {
            jSith.forEach(this.selectedQueries, (_: any, s: any) => {
                s.txt = S25Util.toStr(S25Util.coalesce(s.txt, s.itemName));
                s.itemName = S25Util.toStr(S25Util.coalesce(s.itemName, s.txt));
                s.chosen = true;

                let query = S25Util.array.getByProp(this.queries, "itemId", s.itemId);
                if (!query) {
                    this.queries.unshift(s);
                } else {
                    query.chosen = true;
                }

                if (!this.chosenDropdown) {
                    this.chosenDropdown = s;
                }
            });

            jSith.forEach(this.queries, (_: any, s: any) => {
                s.txt = s.txt || s.itemName;
                s.itemName = s.itemName || s.txt;
            });

            this.dropdownModel.items = this.queries;

            this.init = true;
            this.cd.detectChanges();
        });
    }
}
